/*-----------------------------------------------
 共通JS
-----------------------------------------------*/

$(function(){

  createHamburgerMenu();
  createAccordion();
  createPageTop();
  createTab();
  $('[data-js="js-matchHeight"]').matchHeight();
  urlHashClickScroll();
  convertMail();


//  $(window).on('load', function() {

//    createAnchorScroll();
    clickAnchorScroll();

//  });

});

function clickAnchorScroll () {


  $('a[href^="#"]:not([class*="modal"])').on('click', function () {
      if( $(this).closest('[data-js="tab"]').length > 0 ) { return false; }

      var speed = 1000;
      var hh = $(".l-header__inner").innerHeight();
      var href= $(this).attr("href");
      var $target = $(href == "#" || href == "" ? 'html' : href);

      if ($target.length) {
        var pos = $target.offset().top - hh;
        $("html, body").animate({scrollTop:pos}, speed, "swing");
      }

      return false;
  });

}


// ページ外からのアンカーリンク遷移でもスクロールさせる
function urlHashClickScroll () {
  var headerHeight = $('.l-header__inner').outerHeight();
  var urlHash = location.hash;
  if(urlHash) {
      $('body,html').stop().scrollTop(0);
      setTimeout(function(){
          var target = $(urlHash);
          var position = target.offset().top - headerHeight;
          $('body,html').stop().animate({scrollTop:position}, 1000);
      }, 100);
      $('.js-scroll-link').on('click', function () {
        $('body,html').stop().animate({scrollTop:position}, 1000);
      });
  }
}

// メールの暗号化とコンバート
function convertMail () {
  $('.js-mail').on('click', function (e) {
    e.preventDefault();
    //Mをコンバートして正規のメールアドレスに戻す処理
    function converter(M){
      var str="", str_as=""; //変数を設定
      for(var i=0;i<M.length;i++){ //Mの文字数分だけ回す
      str_as = M.charCodeAt(i); //Mの1文字ずつをUnicodeを返す
      str += String.fromCharCode(str_as + 1); //正規のunicodeに戻す。暗号化したUnicode+1。
      }
      return str;
    }
    //作成したconverter(M)を使って、暗号化（Unicode -1）した文字列を戻する処理
    //前半：mailto: 後半(converter内)：暗号化したメールアドレスをconvertして正規のメールアドレスに戻す処理
    var mail_to = String.fromCharCode(109,97,105,108,116,111,58)+
        converter(String.fromCharCode(103,103,113,49,47,49,48,63,103,104,113,110,102,104,109,45,98,110,45,105,111,
        62,114,116,97,105,100,98,115,60));
    // メール画面へ遷移
    location.href = mail_to;
  });
}


function createAccordion () {
  $('[data-js="accordion"] .c-accordion__title').on('click', function () {
    $(this).toggleClass('open');
    $('+.c-accordion__body',this).slideToggle();
  });
}

//  pageTop scroll
function createPageTop () {
  var $pagetop = $('a[data-js="pagetop"]');
  if ( $(window).width() > 768 ) $pagetop.hide();
  window.addEventListener('scroll', function() {
    if ( $(window).scrollTop() > 200 ) {
      $pagetop.fadeIn();
    } else {
      $pagetop.fadeOut();
    }
  });

  $pagetop.on('click', function (e) {
    e.preventDefault();
    $('html,body').animate({scrollTop:0},1000);
    return false;
  });
}

// Tab
function createTab () {
  var t_item = $('[data-js="tab"] .c-tab__select .c-tab__item > a:not([target="_blank"])');
  var t_article = $('[data-js="tab"] .c-tab__body .c-tab__article');

  t_item.on('click', function (e) {
    e.preventDefault();
    var t_target = $(this).attr('href');
    //active付与
    $(this).parent().siblings().removeClass('is-active');
    $(this).parent().addClass('is-active');
    //show付与
    $(t_target).siblings().removeClass('is-show')
    $(t_target).addClass('is-show');
  });

  // hash付きで遷移した場合、対象のタブを表示
  var hash = location.hash;
  var t = $('[data-js="tab"] .c-tab__select .c-tab__item > a[href="'+ hash +'"]');
  var hash_target = $(hash);
  if ( hash.length ) {
    t.parent().siblings().removeClass('is-active');
    t.parent().addClass('is-active');
    hash_target.siblings().removeClass('is-show');
    hash_target.addClass('is-show');
  }

  $(window).on('resize', function(){
    //横にスライドできます表記出現条件
    $('[data-js="tab"]').each(function () {
      var tab_item = $(this).find('.c-tab__item');
      var totalTabWidth = 0;
      tab_item.each(function() {
        totalTabWidth += tab_item.outerWidth();
      })
      if ( totalTabWidth >= $(window).width() ) {
        $(this).addClass('add_attention');
      } else {
        $(this).removeClass('add_attention');
      }
    });
  });
}